<template>
  <v-container fluid class="px-0">
    <v-card>
      <v-row class="my-1">
        <v-col cols="8">
          <h1>TOTAL BODAS</h1>
          <v-simple-table v-if="datosEv && datosEv.length > 0" style="width: max-content">
            <thead>
              <tr>
                <th>
                  <v-menu
                    v-model="menuEv"
                    :close-on-content-click="false"
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        style="display:inline-block;max-width: 280px;"
                        :value="('0' + new Date(seleccionadoEvo).toLocaleDateString('esp', { day: 'numeric' })).slice(-2) + ' - ' + new Date(seleccionadoEvo).toLocaleDateString('esp', { month: 'short' }) + ' - ' +new Date(seleccionadoEvo).toLocaleDateString('esp', { year: 'numeric' })"
                        v-bind="attrs"
                        class="v-superdense-input"
                        filled 
                        placeholder="Fecha"
                        dense 
                        hide-details="auto"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :first-day-of-week="1"
                      v-model="seleccionadoEvo"
                      no-title
                      @input="getEvolutivo"
                    ></v-date-picker>
                  </v-menu>
                </th>
                <th>CONFIRMADAS</th>
                <th>DIF S/OBJ</th>
                <th>DIF HOY S/A.A.</th>
                <th>PENDIENTES</th>
                <th>PERDIDAS</th>
                <th>% ÉXT</th>
                <th>% ÉXT A.A.</th>
                <th>PENDIENTES</th>
                <th>PERDIDAS</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(year,i) in datosEv">
                <td>{{ year.y }}</td>
                <td style="border-left: 1px solid var(--v-secondary-base);text-align:center;font-size: larger;">{{ year.conf || 0 }}</td>
                <td style="border-left: 1px solid var(--v-secondary-base);text-align:center">{{ year.diffobj || 0 }}</td>
                <td style="border-left: 1px solid var(--v-secondary-base);text-align:center">{{ year.diffaa || 0 }}</td>
                <td style="border-left: 1px solid var(--v-secondary-base);text-align:center">{{ year.pte || 0 }}</td>
                <td style="border-left: 1px solid var(--v-secondary-base);text-align:center">{{ year.prd || 0 }}</td>
                <td style="border-left: 1px solid var(--v-secondary-base);text-align:center">{{ Math.round(year.ext || 0) }}%</td>
                <td style="border-left: 1px solid var(--v-secondary-base);text-align:center">{{ Math.round(year.extaa || 0) }}%</td>
                <td style="border-left: 1px solid var(--v-secondary-base);text-align:center">{{ (year.pteC || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}€</td>
                <td style="border-left: 1px solid var(--v-secondary-base);text-align:center">{{ (year.prdC || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") }}€</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
        <v-col cols="4">
          <v-tabs v-model="tabsEv" grow>
            <v-tab>OBJETIVO</v-tab>
            <v-tab>PENDIENTES Y PERDIDAS</v-tab>
            <v-tab>% ÉXITO</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabsEv">
            <v-tab-item :eager="true">
              <canvas id="graph1"></canvas>
            </v-tab-item>
            <v-tab-item :eager="true">
              <canvas id="graph2"></canvas>
            </v-tab-item>
            <v-tab-item :eager="true">
              <canvas id="graph3"></canvas>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card>
    <v-divider class="my-5"></v-divider>
    <v-card>
      <h1>LEADS BODAS</h1>
      <v-row class="my-1">
        <v-col cols="4">
          <canvas id="graphLead1"></canvas>
        </v-col>
        <v-col cols="4">
          <canvas id="graphLead2"></canvas>
        </v-col>
        <v-col cols="4">
          <canvas id="graphLead3"></canvas>
        </v-col>
      </v-row>
      <h1>LEADS POR MESES</h1>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="3">
              <v-row class="pa-2 justify-center align-center">
                <v-spacer></v-spacer>
                <v-btn icon fab @click="year--"><v-icon x-large>mdi-chevron-left</v-icon></v-btn>
                  <h3 :class="`primary--text text-center py-2 my-2 ${$vuetify.theme.dark ? 'pickerDark' : 'picker'}`" style="font-size: xx-large;">
                    {{ year }}
                  </h3>
                <v-btn :style="year != new Date().getFullYear() ? '' : 'visibility:hidden'" icon fab @click="year++"><v-icon x-large>mdi-chevron-right</v-icon></v-btn>
                <v-spacer></v-spacer>
              </v-row>
            </v-col>
            <v-col cols="12" md="3">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                >
                <template v-slot:activator="{ on, attrs }">
                  <h3 :class="`primary--text text-center mx-auto py-2 my-2 ${$vuetify.theme.dark ? 'pickerDark' : 'picker'}`" v-bind="attrs" v-on="on" style="max-width: 100%; font-size: xx-large; text-overflow: ellipsis;overflow: hidden;white-space: nowrap;" :title="selectedMonths.length > 0 ? (meses.filter((m,i) => selectedMonths.map(s => parseInt(s.slice(-2)) ).includes(i+1)).join(', ')) : 'TODOS'">
                    {{ selectedMonths.length > 0 && selectedMonths.length != 12 ? (meses.filter((m,i) => selectedMonths.map(s => parseInt(s.slice(-2)) ).includes(i+1)).join(', ')) : 'TODOS' }}
                  </h3>
                </template>
                <v-date-picker
                  class="noHeader"
                  multiple
                  v-model="selectedMonths"
                  type="month"
                  :first-day-of-week="1"
                  no-title
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3">
              <v-menu
                content-class="elevation-0"
                eager
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                location="end"
              >
                <template v-slot:activator="{ on, attrs }">
                  <h3 @click="openEspacios" :class="`primary--text text-center mx-auto py-2 my-2 ${$vuetify.theme.dark ? 'pickerDark' : 'picker'}`" v-bind="attrs" v-on="on" style="max-width: 100%; font-size: xx-large; text-overflow: ellipsis;overflow: hidden;white-space: nowrap;" :title="selectedEspacios.length > 0 && selectedEspacios.length != 11 ? (espacioLeads.filter((m,i) => selectedEspacios.includes(m.val)).map(l => l.title).join(', ')) : 'TODOS'">
                    {{ selectedEspacios.length > 0 && selectedEspacios.length != 11 ? (espacioLeads.filter((m,i) => selectedEspacios.includes(m.val)).map(e => e.title).join(', ')) : 'TODOS' }}
                  </h3>
                </template>
              <v-select ref="espaciosSelect" style="display:none" multiple v-model="selectedEspacios" :items="espacioLeads" :item-text="item => item.title" :item-value="item => item.val">
                <template v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @mousedown.prevent
                    @click="toggle"
                  >
                    <v-list-item-action>
                      <v-icon :color="selectedEspacios.length > 0 ? 'primary' : ''">
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Seleccionar Todos
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
            </v-menu>
            </v-col>
            <v-col cols="12" md="3">
              <h3 :class="`primary--text text-center mx-auto py-2 my-2 ${$vuetify.theme.dark ? 'pickerDark' : 'picker'}`" style="font-size: xx-large; text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
                LEADS: {{ filteredLeads.length }}
              </h3>
            </v-col>
          </v-row>
          <v-simple-table dense style="width:min-content !important;table-layout: fixed;">
            <thead>
              <tr>
                <th colspan="3" v-for="y in 3"><h1 class="text-center">{{ year+(y-1) }}</h1></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <template v-for="y in 3">
                  <td style="white-space:nowrap;border-left: 1px solid black;">TOTAL {{ year+(y-1) }}</td>
                  <td>{{ ((filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == null && l.calidad == 1).length || 0) + (filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == 1).length || 0) + (filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == 4).length || 0) + (filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == 2).length)) }}</td>
                  <td style="white-space: nowrap;">100 %</td>
                </template>
              </tr>
              <tr>
                <template v-for="y in 3">
                  <td style="white-space:nowrap;border-left: 1px solid black;">DPTO CAPTACIÓN</td>
                  <td>{{ filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == null && l.calidad == 1).length }}</td>
                  <td style="white-space: nowrap;">{{ Math.round((filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == null && l.calidad == 1).length /(((filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == null && l.calidad == 1).length || 0) + (filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == 1).length || 0) + (filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == 4).length || 0) + (filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == 2).length)) || 1)) * 100) }} %</td>
                </template>
              </tr>
              <tr>
                <template v-for="y in 3">
                  <td style="white-space:nowrap;border-left: 1px solid black;">DPTO COMERCIAL</td>
                  <td>{{ filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == 1).length }}</td>
                  <td style="white-space: nowrap;">{{ Math.round((filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == 1).length /(((filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == null && l.calidad == 1).length || 0) + (filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == 1).length || 0) + (filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == 4).length || 0) + (filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == 2).length)) || 1)) * 100) }} %</td>
                </template>
              </tr>
              <tr>
                <template v-for="y in 3">
                  <td style="white-space:nowrap;border-left: 1px solid black;">GANADOS</td>
                  <td>{{ filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == 4).length }}</td>
                  <td style="white-space: nowrap;">{{ Math.round((filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == 4).length /(((filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == null && l.calidad == 1).length || 0) + (filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == 1).length || 0) + (filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == 4).length || 0) + (filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == 2).length)) || 1)) * 100) }} %</td>
                </template>
              </tr>
              <tr>
                <template v-for="y in 3">
                  <td style="white-space:nowrap;border-left: 1px solid black;">PERDIDOS</td>
                  <td>{{ filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == 2).length }}</td>
                  <td style="white-space: nowrap;">{{ Math.round((filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == 2).length /(((filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == null && l.calidad == 1).length || 0) + (filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == 1).length || 0) + (filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == 4).length || 0) + (filteredLeads.filter(l => l.fechaEv?.slice(0,4) == year+(y-1) && l.estado == 2).length)) || 1)) * 100) }} %</td>
                </template>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card>
    <div style="max-width: fit-content;position: fixed;right:20px; bottom: 20px;z-index:100000" bottom class="d-flex mt-2 ms-auto">
      <v-btn color="primary" fab @click="exportar" large class="me-4">
        <v-icon>mdi-microsoft-excel</v-icon>
      </v-btn>
    </div>
  </v-container>
</template>

<script>

import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { parseDate } from "@/utils";
import FileSaver from 'file-saver';
import * as ExcelJS from "exceljs";

export default {
  data() {
    return {
      menu: false,
      menu2: false,
      year: new Date().getFullYear(),
      selectedMonths: [(new Date(Date.now() - (new Date().getTimezoneOffset()*60000)).toISOString().slice(0,7))],
      selectedEspacios: [0,1,2,3,4,5,6,7,8,9,16],
      espacio: null,
      meses:["ENE","FEB","MAR","ABR","MAY","JUN","JUL","AGO","SEP","OCT","NOV","DIC"],
      tabsEv: 0,
      menuEv: false,
      menuLeads: false,
      datosEv: [],
      leadsMes: [],
      seleccionadoLeads: new Date().getFullYear(),
      seleccionadoEvo: new Date().toISOString().split('T')[0],
      dateLeads: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
      chart1: null,
      chart2: null,
      chart3: null,
      chartLeads1: null,
      chartLeads2: null,
      specialColorBorder:{
        success: 'rgba(76, 175, 80, 1)',
        info: 'rgba(54, 162, 235, 1)',
        warning: 'rgba(251, 140, 0, 1)',
        error: 'rgba(255, 82, 82, 1)'
      },
      specialColor:{
        success: 'rgba(76, 175, 80, 0.2)',
        info: 'rgba(54, 162, 235, 0.2)',
        warning: 'rgba(251, 140, 0, 0.2)',
        error: 'rgba(255, 82, 82, 0.2)'
      },
      espacioLeads:[
        {title: "PTE", val : 0},
        {title: "PIS", val : 1},
        {title: "HOT", val : 2}, 
        {title: "LAL", val : 3}, 
        {title: "LAUS", val : 4}, 
        {title: "PAL", val : 5}, 
        {title: "MDP", val : 16}, 
        {title: "LLOTJA", val : 8},
        {title: "VDV", val : 9}, 
        {title: "CAT ARA", val : 6}, 
        {title: "CAT CAT", val : 7}
      ],
    };
  },
  mounted(){
    this.getEvolutivo()
    this.getLeads()
    this.getLeadsMes()
  },
  computed:{
    icon () {
      if (this.allEspacios) return 'mdi-close-box'
      if (this.selectedEspacios.length > 0) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    allMonths(){
      return this.selectedMonths.length >= 12
    },
    allEspacios(){
      return this.selectedEspacios.length >= 11
    },
    filteredLeads(){
      var lead = this.leadsMes
      if (this.selectedMonths.length > 0) lead = lead.filter(l => this.selectedMonths.map(sel => sel.slice(-2)).includes(l.fecha.slice(5,7)))
      if (this.selectedEspacios.length > 0) lead = lead.filter(l => this.selectedEspacios.includes(l.espacio))
      return lead
    },
    allSelected(){
      return this.selectedEspacios.length === this.espacioLeads.length
    }
  },
  methods: {
    async openEspacios(){
      await this.$nextTick(() => {
        this.$refs.espaciosSelect.activateMenu();
      })
    },
    toggle(){
      this.$nextTick(() => {
        if (this.allSelected) {
          this.selectedEspacios = []
        } else {
          this.selectedEspacios = this.espacioLeads.slice()
        }
      })
    },
    async getLeadsMes(){
      const { data } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/leads/`,
      });
      this.leadsMes = data
    },
    parseDate,
    async getEvolutivo() {
      if(this.seleccionadoEvo == null) return;
      this.loading = true;
      const { data } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/listado/evolutivo/${this.seleccionadoEvo}`,
      });

      this.datosEv = data.map((m) => {
        return m;
      });

      this.datosEv.forEach((e,i) => {
        e.y += i
      });
      const self = this

      if(this.chart1) this.chart1.destroy()
      if(this.chart2) this.chart2.destroy()
      if(this.chart3) this.chart3.destroy()

      this.chart1 = new Chart(document.getElementById("graph1"), 
        {
          type: 'line',
          data: {
            labels: this.datosEv.map(r => r.y),
            datasets: [
              {
                label: 'CONFIRMADAS',
                data: this.datosEv.map(r => (r.conf || 0)),
                fill: false,
                backgroundColor: [
                  this.specialColor['success']
                ],
                borderColor: [
                  this.specialColorBorder['success']
                ],
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['success'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                }
              },
              {
                label: 'DIFERENCIA S/OBJETIVO',
                data: this.datosEv.map(r => (r.diffobj || 0)),
                fill: false,
                backgroundColor: [
                this.specialColor['warning']
              ],
                borderColor: [
                this.specialColorBorder['warning']
                ],
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['warning'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                }
              },
              {
                label: 'DIFERENCIA S/A.A',
                data: this.datosEv.map(r => (r.diffaa || 0)),
                fill: false,
                backgroundColor: [
                  this.specialColor['info']
                ],
                borderColor: [
                  this.specialColorBorder['info']
                ],
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['info'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                }
              }
            ]
          },
          options: {
            scales: {
              y: {
                grid: {
                  color: ({ tick }) => tick.value == 0 ? 'black' : Chart.defaults.borderColor
                },
                max: (scale) => (
                  scale.chart.data.datasets.reduce((acc, curr) => {
                    const max = Math.max(...curr.data);
                    acc = max > acc ? max : acc;
                    return Math.max(acc, 60);
                  }, Number.MIN_SAFE_INTEGER)),
                min: -60,
                ticks: {
                  autoSkip: false,
                  stepSize: 5,
                  display: true,
                }
              }
            },
            layout: {
              padding: {
                right: 10,
                top: 10,
              }
            }
          },
          plugins: [ChartDataLabels],
        }
      )
      this.chart2 = new Chart(document.getElementById("graph2"), 
        {
          type: 'line',
          data: {
            labels: this.datosEv.map(r => r.y),
            datasets: [
              {
                label: 'CONFIRMADAS',
                data: this.datosEv.map(r => (r.conf || 0)),
                fill: false,
                backgroundColor: [
                  this.specialColor['success']
                ],
                borderColor: [
                  this.specialColorBorder['success']
                ],
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['success'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                }
              },
              {
                label: 'PENDIENTES',
                data: this.datosEv.map(r => (r.pte || 0)),
                fill: false,
                backgroundColor: [
                  this.specialColor['warning']
                ],
                borderColor: [
                  this.specialColorBorder['warning']
                ],
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['warning'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                },
                tooltip: {
                  callbacks: {
                    label: function(context) {
                      return 'PENDIENTES: ' + context.formattedValue + ' (' + (self.datosEv[context.dataIndex].pteC || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + ' €)'
                    }
                  }
                },
              },
              {
                label: 'PERDIDAS',
                data: this.datosEv.map(r => (r.prd || 0)),
                fill: false,
                backgroundColor: [
                  this.specialColor['error'],
                ],
                borderColor: [
                  this.specialColorBorder['error'],
                ],
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['error'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                },
                tooltip: {
                  callbacks: {
                    label: function(context) {
                      return 'PERDIDAS: ' + context.formattedValue + ' (' + (self.datosEv[context.dataIndex].prdC || 0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".") + ' €)'
                    }
                  }
                },
              }
            ],
            options: {
              scales: {
                y: {
                  beginAtZero: true,
                  ticks: {
                    autoSkip: false,
                    stepSize: 5,
                    display: true
                  }
                }
              }
            }
          },
          plugins: [ChartDataLabels],
        }
      )
      this.chart3 = new Chart(document.getElementById("graph3"), 
        {
          type: 'line',
          data: {
            labels: this.datosEv.map(r => r.y),
            datasets: [
              {
                label: '% ÉXITO',
                data: this.datosEv.map(r => Math.round(r.ext || 0)),
                fill: false,
                backgroundColor: [
                  this.specialColor['success']
                ],
                borderColor: [
                  this.specialColorBorder['success']
                ],
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['success'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                },
              },
              {
                label: '% ÉXITO A.A',
                data: this.datosEv.map(r => Math.round(r.extaa || 0)),
                fill: false,
                backgroundColor: [
                  this.specialColor['info']
                ],
                borderColor: [
                  this.specialColorBorder['info']
                ],
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['info'],
                  anchor: 'end',
                  align: 'top',
                  clamp: true,
                  offset: -5
                },
              }
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: false,
                ticks: {
                    autoSkip: false,
                    stepSize: 5,
                    display: true
                  }
              }
            }
          },
          plugins: [ChartDataLabels],
        }
      )
    this.loading = false;
    },
    async getLeads() {
      if(this.seleccionadoLeads == null) return;
      this.loading = true;
      const { data } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/leads/graph/${(this.seleccionadoLeads)}`,
      });

      this.datosLeads = data[0]
      this.datosLeadsNext = data[1]
      this.datosLeadsNext2 = data[2]

      if(this.chartLeads1) this.chartLeads1.destroy()
      if(this.chartLeads2) this.chartLeads2.destroy()
      if(this.chartLeads3) this.chartLeads3.destroy()

      this.chartLeads1 = new Chart(document.getElementById("graphLead1"), 
        {
          type: 'bar',
          data: {
            labels: [new Date().getFullYear()],
            datasets: [
              {
                label: 'PERDIDOS',
                data: [Math.abs(this.datosLeads.perdidos || 0)*-1],
                fill: false,
                backgroundColor: [
                  this.specialColor['error']
                ],
                borderColor: [
                  this.specialColorBorder['error']
                ],
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['error'],
                  anchor: 'center',
                  align: 'center',
                  clamp: true,
                  offset: -5
                }
              },
              {
                label: 'GANADOS',
                data: [(this.datosLeads.ganados || 0)],
                fill: false,
                backgroundColor: [
                  this.specialColor['success']
                ],
                borderColor: [
                  this.specialColorBorder['success']
                ],
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['success'],
                  anchor: 'center',
                  align: 'center',
                  clamp: true,
                  offset: -5
                }
              },
              {
                label: 'DPTO COMERCIAL',
                data: [(this.datosLeads.comercial || 0)],
                fill: false,
                backgroundColor: [
                  this.specialColor['warning']
                ],
                borderColor: [
                  this.specialColorBorder['warning']
                ],
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['warning'],
                  anchor: 'center',
                  align: 'center',
                  clamp: true,
                  offset: -5
                }
              },
              {
                label: 'DPTO CAPTACIÓN',
                data: [(this.datosLeads.captacion || 0)],
                fill: false,
                backgroundColor: [
                  this.specialColor['info']
                ],
                borderColor: [
                  this.specialColorBorder['info']
                ],
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['info'],
                  anchor: 'center',
                  align: 'center',
                  clamp: true,
                  offset: -5
                }
              },
              {
                label: 'TOTAL',
                data: [(this.datosLeads.captacion || 0)+(this.datosLeads.comercial || 0)+(this.datosLeads.ganados || 0)+(this.datosLeads.perdidos || 0)],
                fill: false,
                backgroundColor: [
                  "rgba(128,128,128,0.2)"
                ],
                borderColor: [
                  "gray"
                ],
                borderWidth: 1,
                datalabels: {
                  color: "gray",
                  anchor: 'center',
                  align: 'center',
                  clamp: true,
                  offset: -5
                }
              },
            ]
          },
          options: {
            scales: {
              x: {
                stacked: true,
              },
              y: {
                stacked: true,
                grid: {
                  color: function(ctx) {
                    return (ctx.tick.label === '0' ? 'black' : 'rgba(0, 0, 0, 0.1)')
                  } 
                }
              }
            },
            plugins:{
              legend: {
                labels: {
                  boxWidth: 15
                },
                reverse: true
              },
              title: {
                color: '#d39652',
                display: true,
                text: 'TOTAL LEADS ' + new Date().getFullYear(),
                position:'top',
                align:'center',
                font: {
                  size: 24,
                }
              },
              datalabels:{
                formatter: function(value, context) {
                  return value + ' (' + Math.round((value / (context.chart.data.datasets[4].data[context.dataIndex] || 1)) * 100) + ' %)';
                }
              },
              tooltip: {
                callbacks: {
                  label: function(context) {
                    return ["Todos los leads en perdidos","Todos los leads en ganados","Todos los leads en dpto comercial","Leads de calidad (sólo con la Q) en dpto captación","Suma de los leads totales"][context.datasetIndex] + ": " + context.dataset.data[context.dataIndex];
                  }
                }
              }
            },
          },
          plugins: [ChartDataLabels],
        }
      )

      this.chartLeads2 = new Chart(document.getElementById("graphLead2"), 
        {
          type: 'bar',
          data: {
            labels: [new Date().getFullYear()+1],
            datasets: [
              {
                label: 'PERDIDOS',
                data: [Math.abs(this.datosLeadsNext.perdidos || 0)*-1],
                fill: false,
                backgroundColor: [
                  this.specialColor['error']
                ],
                borderColor: [
                  this.specialColorBorder['error']
                ],
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['error'],
                  anchor: 'center',
                  align: 'center',
                  clamp: true,
                  offset: -5
                }
              },
              {
                label: 'GANADOS',
                data: [(this.datosLeadsNext.ganados || 0)],
                fill: false,
                backgroundColor: [
                  this.specialColor['success']
                ],
                borderColor: [
                  this.specialColorBorder['success']
                ],
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['success'],
                  anchor: 'center',
                  align: 'center',
                  clamp: true,
                  offset: -5
                }
              },
              {
                label: 'DPTO COMERCIAL',
                data: [(this.datosLeadsNext.comercial || 0)],
                fill: false,
                backgroundColor: [
                  this.specialColor['warning']
                ],
                borderColor: [
                  this.specialColorBorder['warning']
                ],
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['warning'],
                  anchor: 'center',
                  align: 'center',
                  clamp: true,
                  offset: -5
                }
              },
              {
                label: 'DPTO CAPTACIÓN',
                data: [(this.datosLeadsNext.captacion || 0)],
                fill: false,
                backgroundColor: [
                  this.specialColor['info']
                ],
                borderColor: [
                  this.specialColorBorder['info']
                ],
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['info'],
                  anchor: 'center',
                  align: 'center',
                  clamp: true,
                  offset: -5
                }
              },
              {
                label: 'TOTAL',
                data: [(this.datosLeadsNext.captacion || 0)+(this.datosLeadsNext.comercial || 0)+(this.datosLeadsNext.ganados || 0)+(this.datosLeadsNext.perdidos || 0)],
                fill: false,
                backgroundColor: [
                  "rgba(128,128,128,0.2)"
                ],
                borderColor: [
                  "gray"
                ],
                borderWidth: 1,
                datalabels: {
                  color: "gray",
                  anchor: 'center',
                  align: 'center',
                  clamp: true,
                  offset: -5
                }
              },
            ]
          },
          options: {
            scales: {
              x: {
                stacked: true,
              },
              y: {
                stacked: true,
                grid: {
                  color: function(ctx) {
                    return (ctx.tick.label === '0' ? 'black' : 'rgba(0, 0, 0, 0.1)')
                  } 
                }
              }
            },
            plugins:{
              legend: {
                labels: {
                  boxWidth: 15
                },
                reverse: true
              },
              title: {
                color: '#d39652',
                display: true,
                text: 'TOTAL LEADS ' + (new Date().getFullYear()+1),
                position:'top',
                align:'center',
                font: {
                  size: 24,
                }
              },
              datalabels:{
                formatter: function(value, context) {
                  return value + ' (' + Math.round((value / (context.chart.data.datasets[4].data[context.dataIndex] || 1)) * 100) + ' %)';
                }
              },
              tooltip: {
                callbacks: {
                  label: function(context) {
                    return ["Todos los leads en perdidos","Todos los leads en ganados","Todos los leads en dpto comercial","Leads de calidad (sólo con la Q) en dpto captación","Suma de los leads totales"][context.datasetIndex] + ": " + context.dataset.data[context.dataIndex];
                  }
                }
              }
            },
          },
          plugins: [ChartDataLabels],
        }
      )

      this.chartLeads3 = new Chart(document.getElementById("graphLead3"), 
        {
          type: 'bar',
          data: {
            labels: [new Date().getFullYear()+2],
            datasets: [
              {
                label: 'PERDIDOS',
                data: [Math.abs(this.datosLeadsNext2.perdidos || 0)*-1],
                fill: false,
                backgroundColor: [
                  this.specialColor['error']
                ],
                borderColor: [
                  this.specialColorBorder['error']
                ],
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['error'],
                  anchor: 'center',
                  align: 'center',
                  clamp: true,
                  offset: -5
                }
              },
              {
                label: 'GANADOS',
                data: [(this.datosLeadsNext2.ganados || 0)],
                fill: false,
                backgroundColor: [
                  this.specialColor['success']
                ],
                borderColor: [
                  this.specialColorBorder['success']
                ],
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['success'],
                  anchor: 'center',
                  align: 'center',
                  clamp: true,
                  offset: -5
                }
              },
              {
                label: 'DPTO COMERCIAL',
                data: [(this.datosLeadsNext2.comercial || 0)],
                fill: false,
                backgroundColor: [
                  this.specialColor['warning']
                ],
                borderColor: [
                  this.specialColorBorder['warning']
                ],
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['warning'],
                  anchor: 'center',
                  align: 'center',
                  clamp: true,
                  offset: -5
                }
              },
              {
                label: 'DPTO CAPTACIÓN',
                data: [(this.datosLeadsNext2.captacion || 0)],
                fill: false,
                backgroundColor: [
                  this.specialColor['info']
                ],
                borderColor: [
                  this.specialColorBorder['info']
                ],
                borderWidth: 1,
                datalabels: {
                  color: this.specialColorBorder['info'],
                  anchor: 'center',
                  align: 'center',
                  clamp: true,
                  offset: -5
                }
              },
              {
                label: 'TOTAL',
                data: [(this.datosLeadsNext2.captacion || 0)+(this.datosLeadsNext2.comercial || 0)+(this.datosLeadsNext2.ganados || 0)+(this.datosLeadsNext2.perdidos || 0)],
                fill: false,
                backgroundColor: [
                  "rgba(128,128,128,0.2)"
                ],
                borderColor: [
                  "gray"
                ],
                borderWidth: 1,
                datalabels: {
                  color: "gray",
                  anchor: 'center',
                  align: 'center',
                  clamp: true,
                  offset: -5
                }
              },
            ]
          },
          options: {
            scales: {
              x: {
                stacked: true,
              },
              y: {
                stacked: true,
                grid: {
                  color: function(ctx) {
                    return (ctx.tick.label === '0' ? 'black' : 'rgba(0, 0, 0, 0.1)')
                  } 
                }
              }
            },
            plugins:{
              legend: {
                labels: {
                  boxWidth: 15
                },
                reverse: true
              },
              title: {
                color: '#d39652',
                display: true,
                text: 'TOTAL LEADS ' + (new Date().getFullYear()+2),
                position:'top',
                align:'center',
                font: {
                  size: 24,
                }
              },
              datalabels:{
                formatter: function(value, context) {
                  return value + ' (' + Math.round((value / (context.chart.data.datasets[4].data[context.dataIndex] || 1)) * 100) + ' %)';
                }
              },
              tooltip: {
                callbacks: {
                  label: function(context) {
                    return ["Todos los leads en perdidos","Todos los leads en ganados","Todos los leads en dpto comercial","Leads de calidad (sólo con la Q) en dpto captación","Suma de los leads totales"][context.datasetIndex] + ": " + context.dataset.data[context.dataIndex];
                  }
                }
              }
            },
          },
          plugins: [ChartDataLabels],
        }
      )
      this.loading = false;
    },
    exportar() {
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet('LEADS');
      
      var rows = [
        [null, "AÑO", "MES", "ESPACIOS", "LEADS TOTALES"],
        [null, this.year, this.selectedMonths.length > 0 ? (this.meses.filter((m,i) => this.selectedMonths.map(s => parseInt(s.slice(-2)) ).includes(i+1)).join(', ')) : 'TODOS', this.selectedEspacios.length > 0 ? (this.espacioLeads.filter((esp) => this.selectedEspacios.includes(esp.val)).map(x => x.title).join(', ')) : 'TODOS', this.filteredLeads.length],
      ]
      sheet.insertRows(2, rows)
      var rows2 = [
        [null, this.year, null, null, this.year+1, null, null, this.year+2, null, null],
        [null, "TOTAL", this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year).length, "100 %", "TOTAL", this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year+1).length, "100 %", "TOTAL", this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year+2).length, "100 %"],
        [null, "DPTO CAPTACIÓN", this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year && isNaN(l.estado)).length, parseInt(Math.round((this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year && isNaN(l.estado)).length / (this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year).length || 1)) * 100)) + " %", "DPTO CAPTACIÓN", this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year+1 && isNaN(l.estado)).length, parseInt(Math.round((this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year+1 && isNaN(l.estado)).length / (this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year+1).length || 1)) * 100)) + " %", "DPTO CAPTACIÓN", this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year+2 && isNaN(l.estado)).length, parseInt(Math.round((this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year+2 && isNaN(l.estado)).length / (this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year+2).length || 1)) * 100)) + " %"],
        [null, "DPTO COMERCIAL", this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year && l.estado == 1).length, parseInt(Math.round((this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year && l.estado == 1).length / (this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year).length || 1)) * 100)) + " %", "DPTO COMERCIAL", this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year+1 && l.estado == 1).length, parseInt(Math.round((this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year+1 && l.estado == 1).length / (this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year+1).length || 1)) * 100)) + " %", "DPTO COMERCIAL", this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year+2 && l.estado == 1).length, parseInt(Math.round((this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year+2 && l.estado == 1).length / (this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year+2).length || 1)) * 100)) + " %"],
        [null, "GANADOS", this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year && l.estado == 4).length, parseInt(Math.round((this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year && l.estado == 4).length / (this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year).length || 1)) * 100)) + " %", "GANADOS", this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year+1 && l.estado == 4).length, parseInt(Math.round((this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year+1 && l.estado == 4).length / (this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year+1).length || 1)) * 100)) + " %", "GANADOS", this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year+2 && l.estado == 4).length, parseInt(Math.round((this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year+2 && l.estado == 4).length / (this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year+2).length || 1)) * 100)) + " %"],
        [null, "PERDIDOS", this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year && l.estado == 2).length, parseInt(Math.round((this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year && l.estado == 2).length / (this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year).length || 1)) * 100)) + " %", "PERDIDOS", this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year+1 && l.estado == 2).length, parseInt(Math.round((this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year+1 && l.estado == 2).length / (this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year+1).length || 1)) * 100)) + " %", "PERDIDOS", this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year+2 && l.estado == 2).length, parseInt(Math.round((this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year+2 && l.estado == 2).length / (this.filteredLeads.filter(l => l.fecha.slice(0,4) == this.year+2).length || 1)) * 100)) + " %"],
      ]
      sheet.insertRows(5, rows2)

      sheet.mergeCells(5,2,5,4);
      sheet.mergeCells(5,5,5,7);
      sheet.mergeCells(5,8,5,10);

      workbook.xlsx.writeBuffer()
      .then(buffer => FileSaver.saveAs(new Blob([buffer]), `${"LEADS - EXPORTADO EL " + (new Date().toLocaleDateString('default', { weekday: 'short' }).toUpperCase() + ' - ' + (('0' + new Date().getDate()).slice(-2)) + (new Date().toLocaleString('default', { month: 'short' }).toUpperCase())) + " - " + new Date().getFullYear()}.xlsx`))
      .catch(err => console.log('Error writing excel export', err))
    },
  },
};
</script>

<style>
.noHeader .v-date-picker-header {
  display: none
}
</style>